var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "topButtonList",
      staticClass: "top-button-list",
      on: { mousewheel: _vm.handleMouseWheel },
    },
    [
      _c("div", { on: { mouseover: _vm.topCenterMouseover } }, [
        _vm.taskDetail.stageName == "翻译" || _vm.taskDetail.stageName == "校对"
          ? _c(
              "div",
              {
                staticClass: "top-item",
                on: {
                  click: _vm.confirm,
                  mouseover: function ($event) {
                    _vm.confirmColor = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.confirmColor = "#4a4a4a"
                  },
                },
              },
              [
                _vm.taskConfirmStatus
                  ? _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("\n          取消确认\n        "),
                        ]),
                        _c("file-failed", {
                          attrs: {
                            theme: "outline",
                            size: "19",
                            fill: _vm.confirmColor,
                            strokeWidth: 4,
                          },
                        }),
                        _c("div", {
                          staticClass: "top-item-hover",
                          staticStyle: { top: "12px" },
                        }),
                      ],
                      2
                    )
                  : _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("\n          确认\n        "),
                        ]),
                        _c("file-success", {
                          attrs: {
                            theme: "outline",
                            size: "19",
                            fill: _vm.confirmColor,
                            strokeWidth: 4,
                          },
                        }),
                        _c("div", {
                          staticClass: "top-item-hover",
                          staticStyle: { top: "12px" },
                        }),
                      ],
                      2
                    ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "top-item",
            style: _vm.isAutoSave ? "" : "cursor: no-drop;",
          },
          [
            _c(
              "a-tooltip",
              [
                _c("template", { slot: "title" }, [
                  _vm._v("\n          保存 CTRL+S\n        "),
                ]),
                _c("save", {
                  staticStyle: { position: "relative", top: "2px" },
                  attrs: {
                    theme: "outline",
                    size: "20",
                    fill:
                      _vm.isAutoSave && _vm.changeAll
                        ? _vm.color
                        : _vm.saveColor,
                    strokeWidth: 4,
                  },
                }),
                _c("div", {
                  staticClass: "top-item-hover",
                  staticStyle: { top: "12px" },
                  on: { click: _vm.changeAutoSave },
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm.checkPermission("translate:download")
          ? _c(
              "div",
              {
                staticClass: "top-item downLoad-button",
                on: {
                  mouseover: _vm.changedownLoadColor,
                  mouseout: _vm.changedownLoadColorOut,
                },
              },
              [
                _c(
                  "a-tooltip",
                  {
                    on: {
                      mouseover: function ($event) {
                        $event.stopPropagation()
                      },
                      mouseout: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", [_vm._v("导出")]),
                    ]),
                    _c(
                      "a-dropdown",
                      { attrs: { trigger: ["click"] } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              top: "2px",
                              width: "40px",
                            },
                          },
                          [
                            _c("down-picture", {
                              attrs: {
                                theme: "outline",
                                size: "20",
                                fill: _vm.downLoadColor,
                                strokeWidth: 3,
                              },
                            }),
                            _c("a-icon", {
                              staticStyle: {
                                "font-size": "12px",
                                position: "relative",
                                top: "-3px",
                                right: "-4px",
                              },
                              attrs: { type: "down" },
                            }),
                            _c("div", {
                              staticClass: "top-item-hover",
                              staticStyle: { top: "-4px", width: "46px" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c(
                              "a-menu-item",
                              {
                                key: "1",
                                on: {
                                  click: function ($event) {
                                    return _vm.downLoadImg("JPG")
                                  },
                                },
                              },
                              [_vm._v("下载翻译JPG ")]
                            ),
                            _c(
                              "a-menu-item",
                              {
                                key: "2",
                                on: {
                                  click: function ($event) {
                                    return _vm.downLoadImg("PDF")
                                  },
                                },
                              },
                              [_vm._v("导出PDF ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "top-item",
            on: {
              click: _vm.backData,
              mouseover: function ($event) {
                _vm.undoColor = _vm.color
              },
              mouseout: function ($event) {
                _vm.undoColor = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "a-tooltip",
              [
                _c("template", { slot: "title" }, [
                  _vm._v("\n          撤回 CTRL+Z\n        "),
                ]),
                _c("undo", {
                  staticStyle: { position: "relative", top: "2px" },
                  attrs: {
                    theme: "outline",
                    size: "19",
                    fill: _vm.undoColor,
                    strokeWidth: 4,
                  },
                }),
                _c("div", {
                  staticClass: "top-item-hover",
                  staticStyle: { top: "12px" },
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "top-item",
            on: {
              click: _vm.nextData,
              mouseover: function ($event) {
                _vm.redoColor = _vm.color
              },
              mouseout: function ($event) {
                _vm.redoColor = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "a-tooltip",
              [
                _c("template", { slot: "title" }, [
                  _vm._v("\n          重做 CTRL+Y\n        "),
                ]),
                _c("redo", {
                  staticStyle: { position: "relative", top: "2px" },
                  attrs: {
                    theme: "outline",
                    size: "19",
                    fill: _vm.redoColor,
                    strokeWidth: 4,
                  },
                }),
                _c("div", {
                  staticClass: "top-item-hover",
                  staticStyle: { top: "12px" },
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "top-slider top-item top-item-left",
            staticStyle: { "margin-right": "24px" },
          },
          [
            _c("a-slider", {
              staticStyle: { position: "relative", top: "-1px" },
              attrs: {
                transitionName: "",
                "default-value": 100,
                value: _vm.sliderValue,
                max: 100,
                min: 10,
              },
              on: { change: _vm.sliderHandledChange },
            }),
          ],
          1
        ),
        _vm.checkPermission("rotation-none:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                on: {
                  mouseover: function ($event) {
                    _vm.color1 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color1 = "#4a4a4a"
                  },
                },
              },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          横向\n        "),
                    ]),
                    _c("text-rotation-none", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "18",
                        fill:
                          _vm.textBatchStyle.textLayout == 1
                            ? _vm.color
                            : _vm.color1,
                        strokeWidth: 4,
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: {
                        click: function ($event) {
                          _vm.checkPermission("rotation-none:edit")
                            ? _vm.textLayoutBatchChange(1)
                            : () => {}
                        },
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("rotation-down:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                staticStyle: { "margin-right": "24px" },
                on: {
                  mouseover: function ($event) {
                    _vm.color2 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color2 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          竖向\n        "),
                    ]),
                    _c("text-rotation-down", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "18",
                        fill:
                          _vm.textBatchStyle.textLayout == 2
                            ? _vm.color
                            : _vm.color2,
                        strokeWidth: 4,
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: {
                        click: function ($event) {
                          _vm.checkPermission("rotation-down:edit")
                            ? _vm.textLayoutBatchChange(2)
                            : () => {}
                        },
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-center",
                staticStyle: { "margin-right": "24px", "padding-top": "2px" },
              },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  { staticStyle: { position: "relative", top: "1px" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          对齐方式\n        "),
                    ]),
                    _c("div", {
                      staticClass: "top-item-hover-left",
                      on: {
                        click: function ($event) {
                          _vm.checkPermission("textStyle:edit")
                            ? _vm.textAlignChange("left")
                            : () => {}
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover-center",
                      on: {
                        click: function ($event) {
                          _vm.checkPermission("textStyle:edit")
                            ? _vm.textAlignChange("center")
                            : () => {}
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover-right",
                      on: {
                        click: function ($event) {
                          _vm.checkPermission("textStyle:edit")
                            ? _vm.textAlignChange("right")
                            : () => {}
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticStyle: { width: "82px" } },
                      [
                        _c("align-text-left", {
                          style: { marginRight: "12px" },
                          attrs: {
                            theme: "outline",
                            size: "19",
                            fill:
                              _vm.textBatchStyle.textAlign == "left"
                                ? _vm.color
                                : _vm.color11,
                            strokeWidth: 4,
                          },
                          on: {
                            mouseover: function ($event) {
                              _vm.color11 = _vm.color
                            },
                            mouseout: function ($event) {
                              _vm.color11 = "#4a4a4a"
                            },
                          },
                        }),
                        _c("align-text-center", {
                          style: { marginRight: "12px" },
                          attrs: {
                            theme: "outline",
                            size: "19",
                            fill:
                              _vm.textBatchStyle.textAlign == "center"
                                ? _vm.color
                                : _vm.color12,
                            strokeWidth: 4,
                          },
                          on: {
                            mouseover: function ($event) {
                              _vm.color12 = _vm.color
                            },
                            mouseout: function ($event) {
                              _vm.color12 = "#4a4a4a"
                            },
                          },
                        }),
                        _c("align-text-right", {
                          attrs: {
                            theme: "outline",
                            size: "19",
                            fill:
                              _vm.textBatchStyle.textAlign == "right"
                                ? _vm.color
                                : _vm.color13,
                            strokeWidth: 4,
                          },
                          on: {
                            mouseover: function ($event) {
                              _vm.color13 = _vm.color
                            },
                            mouseout: function ($event) {
                              _vm.color13 = "#4a4a4a"
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item" },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          字体\n        "),
                    ]),
                    _vm.selectTextAreaData.every((item) =>
                      item.characterSentences.every(
                        (text) =>
                          text.fontFamily ==
                          _vm.selectTextAreaData[0].characterSentences[0]
                            .fontFamily
                      )
                    )
                      ? _c(
                          "a-select",
                          {
                            staticStyle: {
                              width: "120px",
                              "font-size": "14px",
                              position: "relative",
                              top: "1px",
                            },
                            attrs: {
                              placeholder: "请选择字体",
                              disabled: !_vm.checkPermission("textStyle:edit"),
                              "defualt-value": _vm.textBatchStyle.fontFamilyId,
                            },
                            on: { change: _vm.textFamilyBatchChange },
                            model: {
                              value: _vm.textBatchStyle.fontFamilyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.textBatchStyle,
                                  "fontFamilyId",
                                  $$v
                                )
                              },
                              expression: "textBatchStyle.fontFamilyId",
                            },
                          },
                          [
                            _vm.textBatchStyle.fontFamilyId &&
                            _vm.fontFamilyList
                              .filter(
                                (font) =>
                                  (_vm.defualtTextType.fontFamilys &&
                                    _vm.defualtTextType.fontFamilys.some(
                                      (item) => item.id == font.id
                                    )) ||
                                  _vm.globalSetting
                              )
                              .findIndex(
                                (item) =>
                                  item.id == _vm.textBatchStyle.fontFamilyId
                              ) == -1
                              ? _c(
                                  "a-select-option",
                                  {
                                    attrs: {
                                      value: _vm.textBatchStyle.fontFamilyId,
                                    },
                                    on: { mouseover: _vm.topCenterMouseover },
                                  },
                                  [
                                    _vm._v(
                                      "\n            自定义字体\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.fontFamilyList.filter(
                                (font) =>
                                  (_vm.defualtTextType.fontFamilys &&
                                    _vm.defualtTextType.fontFamilys.some(
                                      (item) => item.id == font.id
                                    )) ||
                                  _vm.globalSetting
                              ),
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.id,
                                    attrs: { value: item.id },
                                    on: { mouseover: _vm.topCenterMouseover },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.displayName) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _c(
                          "a-select",
                          {
                            staticStyle: { width: "80px" },
                            attrs: {
                              disabled: !_vm.checkPermission("textStyle:edit"),
                              value: "-",
                              placeholder: "请选择字体",
                            },
                            on: { change: _vm.disFontFamilyChange },
                          },
                          [
                            _c(
                              "a-select-option",
                              {
                                key: "-",
                                attrs: { value: "-" },
                                on: { mouseover: _vm.topCenterMouseover },
                              },
                              [_vm._v("-")]
                            ),
                            _vm._l(
                              _vm.fontFamilyList.filter(
                                (font) =>
                                  _vm.defualtTextType.fontFamilys &&
                                  _vm.defualtTextType.fontFamilys.some(
                                    (item) => item.id == font.id
                                  )
                              ),
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.id,
                                    attrs: { value: item.id },
                                    on: { mouseover: _vm.topCenterMouseover },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.displayName) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item-text" },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          字体样式\n        "),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          position: "relative",
                          top: "-1px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.textBatchStyle.styleName || "Regular")
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                staticStyle: { "margin-right": "24px" },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          字号\n        "),
                    ]),
                    _vm.selectTextAreaData.every((item) =>
                      item.characterSentences.every(
                        (text) =>
                          text.fontSize ==
                          _vm.selectTextAreaData[0].characterSentences[0]
                            .fontSize
                      )
                    ) || _vm.phoneticTexts.length
                      ? _c(
                          "a-select",
                          {
                            staticStyle: {
                              width: "70px",
                              "font-size": "14px",
                              position: "relative",
                              top: "2px",
                            },
                            attrs: {
                              "filter-option": _vm.filterOption,
                              "show-search": "",
                              placeholder: "请选择字号",
                              disabled: !_vm.checkPermission("textStyle:edit"),
                              dropdownMenuStyle: {
                                maxHeight: "450px !important",
                              },
                            },
                            on: {
                              search: _vm.fontChange,
                              change: _vm.textFontSizeBatchStyle,
                            },
                            model: {
                              value: _vm.textBatchStyle.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.textBatchStyle, "fontSize", $$v)
                              },
                              expression: "textBatchStyle.fontSize",
                            },
                          },
                          _vm._l(_vm.fontSizes, function (item) {
                            return _c(
                              "a-select-option",
                              {
                                key: item.label,
                                attrs: { value: item.value },
                                on: { mouseover: _vm.topCenterMouseover },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _c(
                          "a-select",
                          {
                            staticStyle: { width: "70px", "font-size": "14px" },
                            attrs: {
                              disabled: !_vm.checkPermission("textStyle:edit"),
                              value: "-",
                              placeholder: "请选择字号",
                            },
                            on: { change: _vm.disFontChange },
                          },
                          [
                            _c(
                              "a-select-option",
                              { key: "-", attrs: { value: "-" } },
                              [_vm._v("-")]
                            ),
                            _vm._l(_vm.fontSizes, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label,
                                  attrs: { value: item.value },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.label) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item top-item-center" },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          加粗\n        "),
                    ]),
                    _c("a-icon", {
                      style: _vm.textBatchStyle.isBold
                        ? { color: _vm.color, position: "relative", top: "2px" }
                        : { position: "relative", top: "2px" },
                      attrs: { type: "bold" },
                      on: { click: _vm.boldChange },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { left: "-7px", top: "12px" },
                      on: { click: _vm.boldChange },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item top-item-center" },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          斜体\n        "),
                    ]),
                    _c("a-icon", {
                      style: _vm.textBatchStyle.isIncline
                        ? { color: _vm.color, position: "relative", top: "2px" }
                        : { position: "relative", top: "2px" },
                      attrs: { type: "italic" },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.isInclineChange },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-center",
                staticStyle: { "margin-right": "24px", "margin-left": "2px" },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          字体颜色\n        "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "color-change",
                        on: {
                          click: function ($event) {
                            _vm.checkPermission("textStyle:edit")
                              ? _vm.colorClick("FontColor")
                              : () => {}
                          },
                        },
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "color-change-text" }, [
                            _c("div", {
                              staticClass: "top-item-hover",
                              staticStyle: { left: "-9px" },
                            }),
                            _vm._v("\n              A\n            "),
                          ]),
                          _c("div", {
                            staticClass: "color-change-bottom",
                            style: {
                              background: _vm.textBatchStyle.fontColor,
                              border: "1px solid rgba(0,0,0,0.25)",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c("colorPicker", {
                      ref: "colorPickerFontColor",
                      attrs: { defaultColor: _vm.textBatchStyle.fontColor },
                      on: { change: _vm.textBatchColorChange },
                      model: {
                        value: _vm.textBatchStyle.fontColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.textBatchStyle, "fontColor", $$v)
                        },
                        expression: "textBatchStyle.fontColor",
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item top-item-center" },
              [
                _c("div", {
                  staticClass: "top-item-border",
                  staticStyle: { left: "-14px" },
                }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          字体描边\n        "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "color-change",
                        on: {
                          click: function ($event) {
                            _vm.checkPermission("textStyle:edit")
                              ? _vm.colorClick("FontStroke")
                              : () => {}
                          },
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "color-change-stroke-text",
                              style: `height:19px;line-height:18px;-webkit-text-stroke: 1px rgba(0,0,0,0.65)`,
                            },
                            [
                              _c("div", {
                                staticClass: "top-item-hover",
                                staticStyle: { left: "-9px" },
                              }),
                              _vm._v("\n              A\n            "),
                            ]
                          ),
                          _c("div", {
                            staticClass: "color-change-bottom-stroke",
                            style: {
                              background: _vm.textBatchStyle.strokeColor,
                              border: "1px solid rgba(0,0,0,0.25)",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c("colorPicker", {
                      ref: "colorPickerFontStroke",
                      attrs: { defaultColor: _vm.textBatchStyle.strokeColor },
                      on: { change: _vm.textStrokeColorBatchChange },
                      model: {
                        value: _vm.textBatchStyle.strokeColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.textBatchStyle, "strokeColor", $$v)
                        },
                        expression: "textBatchStyle.strokeColor",
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("textStyle:view")
          ? _c(
              "div",
              { staticClass: "top-item" },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          描边宽度\n        "),
                    ]),
                    _vm.selectTextAreaData.every((item) =>
                      item.characterSentences.every(
                        (text) =>
                          text.strokeWidth ==
                          _vm.selectTextAreaData[0].characterSentences[0]
                            .strokeWidth
                      )
                    ) || _vm.phoneticTexts.length
                      ? _c(
                          "a-select",
                          {
                            staticStyle: { width: "70px", "font-size": "14px" },
                            attrs: {
                              disabled: !_vm.checkPermission("textStyle:edit"),
                              "show-search": "",
                              placeholder: "请选择描边宽度",
                            },
                            on: { change: _vm.textStrokeWidthBatchChange },
                            model: {
                              value: _vm.textBatchStyle.strokeWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.textBatchStyle, "strokeWidth", $$v)
                              },
                              expression: "textBatchStyle.strokeWidth",
                            },
                          },
                          _vm._l(_vm.strokeWidths, function (i) {
                            return _c(
                              "a-select-option",
                              {
                                key: i,
                                on: { mouseover: _vm.topCenterMouseover },
                              },
                              [
                                _vm._v(
                                  "\n            " + _vm._s(i) + "\n          "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _c(
                          "a-select",
                          {
                            staticStyle: { width: "70px", "font-size": "14px" },
                            attrs: {
                              disabled: _vm.checkPermission("textStyle:edit"),
                              value: "-",
                              placeholder: "请选择描边宽度",
                            },
                            on: { change: _vm.disStrokeChange },
                          },
                          [
                            _c(
                              "a-select-option",
                              { key: "-", attrs: { value: "-" } },
                              [_vm._v("-")]
                            ),
                            _vm._l(_vm.strokeWidths, function (i) {
                              return _c("a-select-option", { key: i }, [
                                _vm._v(
                                  "\n            " + _vm._s(i) + "\n          "
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("background:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-right",
                staticStyle: { "margin-right": "32px" },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          文本框背景颜色\n        "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "color-change",
                        on: {
                          click: function ($event) {
                            _vm.checkPermission("background:edit")
                              ? _vm.colorClick("Back")
                              : () => {}
                          },
                        },
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "color-change-back" }, [
                            _c("div", {
                              staticClass: "top-item-hover",
                              staticStyle: { left: "-1px" },
                            }),
                            _c("span", {
                              staticClass: "iconfont icon-youqitong icon",
                              staticStyle: {
                                "font-size": "18px",
                                position: "relative",
                                top: "-4px",
                                left: "5px",
                              },
                            }),
                          ]),
                          _c("div", {
                            staticClass: "color-change-bottom-back",
                            style: {
                              background: _vm.textBatchStyle.backgroundColor,
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c("colorPicker", {
                      ref: "colorPickerBack",
                      attrs: {
                        defaultColor: _vm.textBatchStyle.backgroundColor,
                      },
                      on: { change: _vm.textBackBatchChange },
                      model: {
                        value: _vm.textBatchStyle.backgroundColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.textBatchStyle, "backgroundColor", $$v)
                        },
                        expression: "textBatchStyle.backgroundColor",
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.taskDetail.targetLang != "JAPANESE" &&
        _vm.checkPermission("zhuozhonghao:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-right",
                staticStyle: { "margin-right": "12px" },
              },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          着重号\n        "),
                    ]),
                    _c("span", {
                      staticClass: "iconfont icon-zhuozhonghao icon",
                      style: _vm.textBatchStyle.hasMark
                        ? { color: _vm.color, position: "relative", top: "2px" }
                        : { position: "relative", top: "2px" },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.markAllText },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.taskDetail.targetLang == "JAPANESE" &&
        _vm.checkPermission("zhuyin:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-right",
                staticStyle: { "margin-right": "10px" },
              },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          注音\n        "),
                    ]),
                    _c("span", {
                      staticClass: "iconfont icon-pinyin icon",
                      staticStyle: {
                        "font-size": "20px",
                        position: "relative",
                        top: "2px",
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.openPhonetic },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("symbol:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-right",
                staticStyle: { "margin-right": "10px" },
                on: {
                  mouseover: function ($event) {
                    _vm.color10 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color10 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          特殊字符\n        "),
                    ]),
                    _c("pound", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.color10,
                        strokeWidth: 3,
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.openEmj },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("jiajv:view")
          ? _c(
              "div",
              {
                staticClass: "top-item top-item-right",
                on: {
                  mouseover: function ($event) {
                    _vm.color3 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color3 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          佳句\n        "),
                    ]),
                    _c("thumbs-up", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.color3,
                        strokeWidth: 3,
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.addSentence },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("addTextArea:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                staticStyle: { "margin-left": "11px" },
                on: {
                  mouseover: function ($event) {
                    _vm.color5 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color5 = "#4a4a4a"
                  },
                },
              },
              [
                _c("div", { staticClass: "top-item-border" }),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          添加文本框\n        "),
                    ]),
                    _c("doc-add", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.isAddText ? _vm.color : _vm.color5,
                        strokeWidth: 3,
                      },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.changeIsAddText },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("addAnnotation:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                on: {
                  mouseover: function ($event) {
                    _vm.color6 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color6 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          添加批注\n        "),
                    ]),
                    _c("local-two", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.isAddAnnotation ? _vm.color : _vm.color6,
                        strokeWidth: 3,
                      },
                      on: { click: _vm.changeIsAddAnnotation },
                    }),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px" },
                      on: { click: _vm.changeIsAddAnnotation },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("note:add") &&
        _vm.departName.indexOf("韩语部") == -1
          ? _c(
              "div",
              {
                staticClass: "top-item",
                staticStyle: { "margin-right": "24px" },
                on: {
                  mouseover: function ($event) {
                    _vm.color7 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color7 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  { attrs: { transitionName: "" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          表记\n        "),
                    ]),
                    _c("form-one", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.color7,
                        strokeWidth: 3,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "down-menu" },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              transitionName: "",
                              trigger: ["click"],
                              placement: "bottomCenter",
                            },
                          },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: _vm.openNote,
                                    },
                                  },
                                  [_vm._v("添加表记")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "2",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: function ($event) {
                                        return _vm.goToTerm(4)
                                      },
                                    },
                                  },
                                  [_vm._v("查看表记")]
                                ),
                              ],
                              1
                            ),
                            _c("a-icon", {
                              attrs: { type: "down" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px", width: "44px" },
                      on: { click: _vm.openNote },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("terms:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                staticStyle: { "margin-right": "24px" },
                on: {
                  mouseover: function ($event) {
                    _vm.color8 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color8 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          术语表\n        "),
                    ]),
                    _c("read-book", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.color8,
                        strokeWidth: 3,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "down-menu" },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              transitionName: "",
                              trigger: ["click"],
                              placement: "bottomCenter",
                            },
                          },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: _vm.addTerm,
                                    },
                                  },
                                  [_vm._v("添加术语表")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "2",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: function ($event) {
                                        return _vm.goToTerm(1)
                                      },
                                    },
                                  },
                                  [_vm._v("查看术语表")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "3",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: function ($event) {
                                        return _vm.goToTerm(3)
                                      },
                                    },
                                  },
                                  [_vm._v("查看关系图")]
                                ),
                              ],
                              1
                            ),
                            _c("a-icon", {
                              attrs: { type: "down" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px", width: "44px" },
                      on: { click: _vm.addTerm },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.checkPermission("termsBack:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                on: {
                  mouseover: function ($event) {
                    _vm.color9 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color9 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n          背景册\n        "),
                    ]),
                    _c("document-folder", {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.color9,
                        strokeWidth: 3,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "down-menu" },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              transitionName: "",
                              trigger: ["click"],
                              placement: "bottomCenter",
                            },
                          },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: _vm.addTermBack,
                                    },
                                  },
                                  [_vm._v("添加背景册")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "2",
                                    on: {
                                      mouseover: _vm.topCenterMouseover,
                                      click: function ($event) {
                                        return _vm.goToTerm(2)
                                      },
                                    },
                                  },
                                  [_vm._v("查看背景册")]
                                ),
                              ],
                              1
                            ),
                            _c("a-icon", {
                              attrs: { type: "down" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "top-item-hover",
                      staticStyle: { top: "12px", width: "44px" },
                      on: { click: _vm.addTermBack },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }